<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: this.$t('소개')
    };
  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5>Single Wallet에서 사용되는 API 안내입니다.</h5>
            <span>* 게임접속 API 실행시 회원가입, 충전, 게임접속이 한번에 처리됩니다.</span>
            <hr />
            <ul>
              <li><label><router-link to="/api/seamless/play">게임접속</router-link>:</label> API서버에 접속링크를 요청합니다.</li>
              <li><label><router-link to="/api/seamless/balance">잔액 콜백</router-link>:</label> 잔액 정보를 사이트로 전송합니다.</li>
              <li><label><router-link to="/api/seamless/bet">배팅 콜백</router-link>:</label> 배팅 정보를 사이트로 전송합니다.</li>
              <li><label><router-link to="/api/seamless/result">결과 콜백</router-link>:</label> 배팅 결과 정보를 사이트로 전송합니다.</li>
              <li><label><router-link to="/api/seamless/cancel">취소 콜백</router-link>:</label> 배팅 취소 정보를 사이트로 전송합니다.</li>
              <!--<li><label><router-link to="/api/seamless/check">사이트 콜백 테스트</router-link>:</label> 사이트에 적용한 API를 전체 테스트합니다.</li>-->
            </ul>
            <hr />
            <p class="text-danger">* 심리스 요청이 실패하는 경우 최대 3회 재전송합니다.<br />의도적으로 재전송을 원하시는 경우 Http Status(상태 코드)를 200이 아닌 500으로 응답하시면 됩니다.</p>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
